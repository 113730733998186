<!--
 * @Author: wyq
 * @Date: 2021-05-17 14:57:08
 * @LastEditTime: 2021-06-08 16:31:42
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\views\mine\ecords.vue
-->
<template>
  <div class="ecords-main">
    <!-- 头部 -->
    <public-header></public-header>
    <!-- 内容 -->
    <div ref="main" class="page_bd">
      <div class="ecords-cen" v-loading="loading">
        <!-- 头部搜索商品数量 -->
        <div class="shop-cen-head">
          <div>
            <span>{{$t('为您找到')}}</span>
            <span class="total-number">{{total|formatNumber}}</span>
            <span>{{$t('条相关数据')}}</span>
          </div>
        </div>
        <!-- 内容部分 -->
        <Content v-bind:contentData="contentData"></Content>

        <!-- 分页 -->
        <el-pagination
          background
          layout="total,prev, pager, next"
          :page-size="page_size"
          :total="total"
          @current-change="pageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 回到顶部 -->
    <back-top></back-top>
    <public-footer></public-footer>
  </div>
</template>

<script>
//组件引入
import SearchHead from '../../components/search/search_head.vue'
import Content from '../../components/body/content.vue'
export default {
  data() {
    return {
      page: 1,
      page_size: 10,
      contentData: [],
      total: 0,
      loading: false,
    }
  },
  methods: {
    init() {
      this.ecordsList()
    },
    ecordsList() {
      this.loading = true
      let params = {
        page: this.page,
        page_size: this.page_size,
        order: this.order,
      }
      this.$service
        .getBrowseRecords(params)
        .then((result) => {
          this.contentData = result.rows
          this.total = result.total
          this.loading = false
        })
        .catch(() => {
          this.contentData = []
          this.total = 0
          this.loading = false
        })
    },
    // 分页 触发子组件搜索方法
    pageChange(e) {
      this.page = e
      this.ecordsList()
      this.$refs['main'].scrollIntoView()
    },
  },
  components: {
    SearchHead,
    Content,
  },
  mounted() {
    this.init()
  },
}
</script>


<style lang="scss" scoped>
.ecords-main {
  .shop-cen-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 10px;
    margin: 0rem 1rem;
    border-bottom: 1px solid #f2f2f2;
    color: #333;
    font-size: 14px;
    .total-number {
      color: #007aff;
      font-weight: 800;
      padding: 0 4px;
    }
  }
  .ecords-cen {
    margin: 20px 0;
    background-color: #fff;
    overflow: hidden;
  }
  .el-pagination {
    margin: 50px 0 40px 0;
    text-align: center;
  }
}
</style>
